<template>
    <button
        v-on="$listeners"
        :class="['rating-button p-1', this.color && `color-${this.color}`]"
    >
        <img
            :src="ratingStarSvg"
            alt="star"
        >
    </button>
</template>

<script>
    export default {
        props: {
            color: Number,
        },
        computed: {
            ratingStarSvg: function () {
                return require('@/assets/images/global/rating-star.svg')
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/ratingStarButton.scss';
</style>
