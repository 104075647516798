<template>
    <div class="d-flex">
        <rating-star-button
            v-for="rating in [1, 2, 3, 4, 5]"
            :key="rating"
            @click="setSelectedRating(rating)"
            @mouseenter="setActiveRating(rating)"
            @focus="setActiveRating(rating)"
            @mouseleave="setActiveRating(null)"
            @blur="setActiveRating(null)"
            :color="dynamicActiveRating && dynamicActiveRating >= rating ? dynamicActiveRating : null"
            class="me-1"
        />
    </div>
</template>

<script>
    import RatingStarButton from '@/components/RatingStarButton'
    export default {
        components: {
            'rating-star-button': RatingStarButton,
        },
        emits: ['selected-rating-change'],
        data() {
            return {
                // clicked rating
                selectedRating: null,
                // hovered or focused rating
                activeRating: null,
            }
        },
        computed: {
            // hovered rating in case that there's any, selected one otherwise
            dynamicActiveRating() {
                return this.activeRating || this.selectedRating
            },
        },
        methods: {
            setSelectedRating(rating) {
                this.$emit('selected-rating-change', rating)
                this.selectedRating = rating
            },

            setActiveRating(rating) {
                this.activeRating = rating
            },
        },
    }
</script>
