<template>
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-8 offset-md-2">
                <div class="row">
                    <div class="col-12 py-3 mb-7 mb-md-10 text-left text-md-center">
                        <img
                            src="@/assets/images/components/navbar/aven.svg"
                            class="logo"
                            alt="Logo"
                        >
                    </div>
                </div>

                <div
                    v-if="currentScreen == Screens.initial"
                    class="d-grid gap-4"
                >
                    <div>
                        <h3 class="m-0">
                            Welcome to Aven!
                        </h3>
                        <h3 class="fw-light m-0">
                            How would you rate your experience?
                        </h3>
                    </div>

                    <rating @selected-rating-change="onSelectedRatingChange" />
                </div>
                <div
                    v-if="currentScreen == Screens.feedbackText"
                    class="d-grid gap-4"
                >
                    <div>
                        <h3 class="m-0">
                            Thanks!
                        </h3>
                        <h3 class="fw-light m-0">
                            We would love your thoughts on how we can improve.
                        </h3>
                    </div>

                    <form
                        class="d-grid gap-4"
                        @submit="onFeedbackSubmit"
                    >
                        <form-text-area
                            name="feedback"
                            placeholder="Anything we can do to make the experience better?"
                            :rows="4"
                            v-model="feedback"
                        />
                        <button class="btn btn-dark">
                            Share
                        </button>
                    </form>
                </div>

                <div v-if="currentScreen === Screens.trustPilot">
                    <h3>Awesome!</h3>
                    <h3 class="fw-light">
                        We would love your rating on TrustPilot.
                    </h3>
                    <p class="text-muted">
                        Trustpilot is like Yelp for businesses. It should only take a minute.
                    </p>

                    <div class="mt-3">
                        <iframe
                            class="border p-md-3"
                            v-if="trustPilotUniqueUrl"
                            :src="trustPilotUniqueUrl"
                            height="1200px"
                            width="100%"
                        />
                        <div v-else>
                            <a
                                class="btn btn-primary d-block"
                                href="https://www.trustpilot.com/evaluate/aven.com"
                            > Add To TrustPilot </a>
                            <button
                                class="btn btn-secondary w-100 mt-2"
                                @click="currentScreen = Screens.final"
                            >
                                No Thanks
                            </button>
                        </div>
                    </div>
                </div>

                <!-- `reviewed` state -->
                <div v-if="currentScreen === Screens.final">
                    <h3 class="heading-3 m-0">
                        Thank you.
                    </h3>
                    <h3 class="fw-light">
                        We appreciate your valuable feedback.
                    </h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Rating from '@/components/Rating'
    import FormTextArea from '@/components/base/FormTextArea'
    import { sendUserFeedbackEmail, createTrustPilotUniqueLink } from '@/services/api'
    import { logger } from '@/utils/logger'

    const Screens = {
        initial: 'initial',
        final: 'final',
        feedbackText: 'feedbackText',
        trustPilot: 'trustPilot',
    }

    export default {
        components: {
            FormTextArea,
            rating: Rating,
        },
        data() {
            return {
                selectedRating: null,
                feedback: '',
                currentScreen: Screens.initial,
                Screens,
                returnToken: this.$route.query?.p?.substr(0, 36),
                trustPilotUniqueUrl: null,
            }
        },
        mounted: async function () {
            this.$logEvent('view_review_us')

            if (this.returnToken) {
                try {
                    // 'https://www.trustpilot.com/evaluate/embed/aven.com?a=asdlka&b=Y29sbGluQGF2ZW4uY29t&c=Collin%20Wikman&e=11527c3befcb1720ae0d4746cf5e8935d8ef40f5'
                    const response = await createTrustPilotUniqueLink(this.returnToken)
                    if (response.data.success === true) {
                        const url = response.data.payload.url
                        this.trustPilotUniqueUrl = url
                        logger.info(`Unique Trust Pilot URL: ${JSON.stringify(url)}`)
                    } else {
                        // Customer will see the generic flow
                        logger.info(response.data.payload)
                    }
                } catch (e) {
                    logger.info('Unable to find applicant with return token')
                }
            }
        },
        methods: {
            onSelectedRatingChange(rating) {
                this.selectedRating = rating
                this.$logEvent('click_button_submit_rating_form', { rating: this.selectedRating })
                this.setCurrentScreen(rating)
            },

            async onFeedbackSubmit(e) {
                this.$logEvent('click_button_submit_rating_feedback_form', { rating: this.selectedRating, feedback: this.feedback })

                e.preventDefault()
                try {
                    await sendUserFeedbackEmail(`${this.selectedRating}-Star Review | ${this.feedback}`)
                } finally {
                    this.currentScreen = Screens.final
                }
            },

            async setCurrentScreen(rating) {
                if (rating >= 4) {
                    this.currentScreen = Screens.trustPilot
                } else {
                    this.currentScreen = Screens.feedbackText
                }
            },
        },
    }
</script>
